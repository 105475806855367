<template lang="pug">
.overflow-y-auto.skif-event-form.absolute.bottom-5.top-5.left-5.z-30.bg-white.shadow.pr-4.pl-6.pt-5.w-module
  .flex.justify-between.items-center
    .text-darkblue.text-xl.font-bold.whitespace-no-wrap.font-SourceSansPro {{ title === 1 ? $t("events.create") : $t("events.update") }}
    i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
      @click='$emit("close")'
    )
  .skif-form__row.--flex
    el-select(
      type='text',
      v-model='currentType',
      filterable,
      :no-match-text='$t("noMatch")',
      :placeholder='$t("reports.not-select")'
    )
      el-option(
        v-for='item in types',
        :key='item.key',
        :label='item.value',
        :value='item.key'
      )
    skif-button(width='28', @click='submitForm', :disabled='loading') {{ title === 1 ? $t("create") : $t("change") }}

  el-form(:model='data', :rules='rules', :disabled='loading', ref='eventForm')
    .skif-form__row(v-if='needShowField("datetime")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("datetime")') *
        | {{ getLabel("datetime") }}
      .skif-form__field
        el-form-item(prop='datetime')
          el-date-picker(
            :key='currentType + key',
            type='datetime',
            v-model='data.datetime',
            :clearable='false',
            :format='dateTimeFormat',
            :picker-options='pickerOptions'
          )

    .skif-form__row(v-if='needShowField("start_date")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("start_date")') *
        | {{ getLabel("start_date") }}
      .skif-form__field
        el-form-item(prop='start_date')
          el-date-picker(
            :key='currentType + key',
            type='datetime',
            :clearable='false',
            v-model='data.start_date',
            :format='dateTimeFormat',
            :picker-options='pickerOptions'
          )

    .skif-form__row(v-if='needShowField("finish_date")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("finish_date")') *
        | {{ getLabel("finish_date") }}
      .skif-form__field
        el-form-item(prop='finish_date')
          el-date-picker(
            :key='currentType + key',
            type='datetime',
            :clearable='false',
            v-model='data.finish_date',
            :format='dateTimeFormat',
            :picker-options='pickerOptions'
          )

    .skif-form__row(v-if='needShowField("user_id")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("user_id")') *
        | {{ getLabel("user_id") }}
      .skif-form__field
        el-form-item(prop='user_id')
          el-select(
            :key='currentType + key',
            v-model='data.user_id',
            filterable,
            :no-match-text='$t("noMatch")',
            :placeholder='$t("reports.not-select")'
          )
            el-option(
              v-for='item in drivers',
              :key='item.id',
              :label='item.name ? `${item.name}(${item.email})` : item.email',
              :value='item.id'
            )

    .skif-form__row(v-if='needShowField("deviation_minutes")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("deviation_minutes")') *
        | {{ getLabel("deviation_minutes") }}
      .skif-form__field
        el-form-item(prop='deviation_minutes')
          el-input-number(
            :key='currentType + key',
            class='w-1/3',
            v-model='data.deviation_minutes',
            :min='0',
            :max='1000',
            :controls='false'
          )

    .skif-form__row(v-if='needShowField("filled")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("filled")') *
        | {{ getLabel("filled") }}
      .skif-form__field
        el-form-item(prop='filled')
          el-input-number(
            :key='currentType + key',
            class='w-1/3',
            v-model='data.filled',
            :min='0',
            :controls='false'
          )
    .skif-form__row(v-if='needShowField("consumed")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("consumed")') *
        | {{ getLabel("consumed") }}
      .skif-form__field
        el-form-item(prop='consumed')
          el-input-number(
            :key='currentType + key',
            class='w-1/3',
            v-model='data.consumed',
            :min='0',
            :controls='false'
          )

    .skif-form__row(v-if='needShowField("stolen")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("stolen")') *
        | {{ getLabel("stolen") }}
      .skif-form__field
        el-form-item(prop='stolen')
          el-input-number(
            :key='currentType + key',
            class='w-1/3',
            v-model='data.stolen',
            :min='0',
            :controls='false'
          )

    .skif-form__row(v-if='needShowField("service_name")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("service_name")') *
        | {{ getLabel("service_name") }}
      .skif-form__field
        el-form-item(prop='service_name')
          el-select(
            :key='currentType + key',
            v-model='data.service_name',
            filterable,
            allow-create,
            default-first-option,
            :no-match-text='$t("noMatch")',
            :placeholder='$t("reports.not-select")'
          )
            el-option(
              v-for='(item, i) in servicesUnical',
              :key='i',
              :label='item',
              :value='item'
            )

    .skif-form__row(v-if='needShowField("motohours")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("motohours")') *
        | {{ getLabel("motohours") }}
      .skif-form__field
        el-form-item(prop='motohours')
          el-input-number(
            :key='currentType + key',
            class='w-1/3',
            v-model='data.motohours',
            :min='0',
            :controls='false'
          )

    .skif-form__row(v-if='needShowField("millage")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("millage")') *
        | {{ getLabel("millage") }}
      .skif-form__field
        el-form-item(prop='millage')
          el-input-number(
            :key='currentType + key',
            class='w-1/3',
            v-model='data.millage',
            :min='0',
            :controls='false'
          )

    .skif-form__row(v-if='needShowField("duration_hours")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("duration_hours")') *
        | {{ getLabel("duration_hours") }}
      .skif-form__field
        el-form-item(prop='duration_hours')
          el-input-number(
            :key='currentType + key',
            class='w-1/3',
            v-model='data.duration_hours',
            :min='0',
            :controls='false'
          )

    .skif-form__row(v-if='needShowField("price")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("price")') *
        | {{ getLabel("price") }}
      .skif-form__field
        el-form-item(prop='price')
          el-input-number(
            :key='currentType + key',
            class='w-1/3',
            v-model='data.price',
            :min='0',
            :controls='false'
          )

    .skif-form__row(v-if='needShowField("guaranteed")')
      .skif-form__field
        el-form-item(prop='guaranteed')
          el-checkbox(
            :key='currentType + key',
            v-model.boolean='data.guaranteed'
          ) {{ $t("events.guaranteed") }}

    .skif-form__row(v-if='needShowField("type_name")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("type_name")') *
        | {{ getLabel("type_name") }}
      .skif-form__field
        el-form-item(prop='type_name')
          el-select(
            :key='currentType + key',
            v-model='data.type_name',
            filterable,
            allow-create,
            default-first-option,
            :no-match-text='$t("noMatch")',
            :placeholder='$t("reports.not-select")'
          )
            el-option(
              v-for='(item, i) in randomTypesUnical',
              :key='i',
              :label='item',
              :value='item'
            )

    .skif-form__row(v-if='needShowField("description")')
      .skif-form__label
        span.skif-form__star(v-if='isRequiredField("description")') *
        | {{ getLabel("description") }}
      .skif-form__field
        el-form-item(prop='description')
          el-input(
            :key='currentType + key',
            v-model='data.description',
            type='textarea',
            maxlength='2000',
            rows='4'
          )
    .skif-form__row
      .skif-form__info-text
        info-icon.skif-form__icon.mr-2
        .skif-form__star *
        | - {{ $t("events.requiredFields") }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import { trackingApi } from '@/api'
import websocketHandler from '@/mixins/websocketHandler.js'
import { typesFields, typesRequiredFields } from '@/utils/events.js'

export default {
  props: {
    unit: Object,
    title: Number,
    eventToUpdate: Object,
  },
  mixins: [websocketHandler],
  components: {
    infoIcon: () => import('@/assets/svg-icons/info.svg'),
  },
  data() {
    const checkRequired = (rule, value, callback) => {
      if (!value && this.isRequiredField(rule.field)) {
        callback(new Error(this.$t('events.validation.required')))
      } else {
        callback()
      }
    }

    const checkNumber = (rule, value, callback) => {
      if (!value && this.isRequiredField(rule.field)) {
        callback(new Error(this.$t('events.validation.number')))
      } else {
        callback()
      }
    }

    return {
      key: 0,
      timeOut: null,
      loading: false,
      currentType: 'filling', // filling, theft, accident, service, waybill, random
      // date time format
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      dateFormat: '',
      timeFormat: '',
      dateTimeFormat: '',
      submitDateTimeFormat: 'yyyy-MM-DD HH:mm:ss',
      data: {
        datetime: '',
        deviation_minutes: '',
        filled: '',
        consumed: '',
        price: '',
        description: '22',
        stolen: '',
        service_name: '',
        motohours: '',
        millage: '',
        duration_hours: '',
        user_id: '',
        type_name: '',
        start_date: '',
        finish_date: '',
        guaranteed: false,
        // coords
        latitude: '',
        longitude: '',
      },
      rules: {
        datetime: [
          {
            type: 'date',
            required: true,
            message: this.$t('events.validation.datetime'),
            trigger: 'change',
          },
        ],
        start_date: [
          {
            type: 'date',
            required: true,
            message: this.$t('events.validation.datetime'),
            trigger: 'change',
          },
        ],
        finish_date: [
          {
            type: 'date',
            required: true,
            message: this.$t('events.validation.datetime'),
            trigger: 'change',
          },
        ],
        deviation_minutes: [{ validator: checkNumber, trigger: 'change' }],
        filled: [{ validator: checkNumber, trigger: 'change' }],
        consumed: [{ validator: checkNumber, trigger: 'change' }],
        stolen: [{ validator: checkNumber, trigger: 'change' }],
        service_name: [{ validator: checkRequired, trigger: 'change' }],
        type_name: [{ validator: checkRequired, trigger: 'change' }],
      },
    }
  },
  computed: {
    ...mapState('dictionary', {
      types: (state) => state.event_type,
    }),
    ...mapGetters('users', ['drivers']),
    ...mapState('login', ['me']),
    ...mapState('events', {
      randomTypes: (state) => state.events,
      lastPoints: (state) => state.lastPoints,
    }),
    services() {
      return this.unit.services || []
    },
    currentFields() {
      const type = this.currentType
      return type && typesFields[type] ? typesFields[type] : []
    },
    requiredFields() {
      const type = this.currentType
      return type && typesRequiredFields[type] ? typesRequiredFields[type] : []
    },
    formEl() {
      return this.$refs['eventForm']
    },
    randomTypesUnical() {
      const res = []
      const items = this.randomTypes || []
      items.forEach((item) => {
        const val = item.type_name
        if (val && !res.includes(val)) {
          res.push(val)
        }
      })
      return res
    },
    servicesUnical() {
      let defaultsItems = [
        'Осмотр',
        'Замена терминала',
        'Установка ДУТ',
        'Замена ДУТ',
        'Калибровка ДУТ',
      ]
      const items = this.services || []
      const lang = this.$i18n.locale()
      if (lang === 'en') {
        defaultsItems = [
          'Inspection',
          'Terminal replacement',
          'FLS installation',
          'FLS replacement',
          'FLS calibration',
        ]
      } else if (lang === 'kz') {
        defaultsItems = [
          'Тексеру',
          'Терминалды ауыстыру',
          'FLS орнату',
          'FLS ауыстыру',
          'FLS калибрлеу',
        ]
      }
      const res = defaultsItems
      items.forEach((item) => {
        const val = item.name
        if (val && !res.includes(val)) {
          res.push(val)
        }
      })
      return res
    },
  },
  watch: {
    currentType(val) {
      this.resetForm()
      this.setDefaults()
      if (val === 'random') {
        this.getRandomTypes()
      }
    },
    'data.datetime': function (val) {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.setCoords(val)
      }, 1500)
    },
  },
  methods: {
    _console() {
      // console.warn.apply(console, arguments)
    },
    setCoordData(coords) {
      if (coords && coords[0] && coords[1]) {
        this.data.latitude = coords[0]
        this.data.longitude = coords[1]
      }
    },
    async setCoords(date) {
      console.log('setCoords', date)
      console.log('setCoords2', this.submitDateTimeFormat)
      try {
        const from = moment(new Date(date)).format(this.submitDateTimeFormat)

        const formData = {
          units: [{ id: this.unit.id }],
          from,
        }
        const res = await trackingApi.postPoint({ formData })
        const coords = res.data.columns.find(
          (item) => item.key === '_marker_latlon'
        ).value
        this.setCoordData(coords)
      } catch (error) {
        this.setCoordsFromWS()
      }
    },
    setCoordsFromWS() {
      const unitId = this.unit.id
      const coords =
        this.lastPoints && this.lastPoints[unitId]
          ? this.lastPoints[unitId]
          : null
      this.setCoordData(coords)
    },
    getLabel(field) {
      let label = this.$t(`events.${field}`)
      // if(this.isRequiredField(field)) {
      //   label = label + '*'
      // }
      return label
    },
    needShowField(name) {
      return name && this.currentFields.includes(name)
    },
    isRequiredField(name) {
      return name && this.requiredFields.includes(name)
    },
    submitForm() {
      this.formEl.validate((valid) => {
        if (valid) {
          this.createOrUpdate()
        } else {
          return false
        }
      })
    },
    createSubmitData() {
      const cleanEmpty = {}
      Object.keys(this.data).forEach((key) => {
        if (
          this.data[key] ||
          (key === 'guaranteed' && this.currentType === 'service')
        ) {
          cleanEmpty[key] = this.data[key]
        }
      })

      const data = Object.assign(
        {
          unit: { id: this.unit.id },
          type: { key: this.currentType },
        },
        cleanEmpty
      )

      const dateField = ['datetime', 'start_date', 'finish_date']
      dateField.forEach((key) => {
        if (data[key]) {
          data[key] = moment(new Date(data[key])).format(
            this.submitDateTimeFormat
          )
        }
      })
      return data
    },
    async createOrUpdate() {
      const data = this.createSubmitData()
      console.log('data to send ', data)
      this.loading = true
      try {
        if (this.title === 1) {
          await this.$store.dispatch('events/CREATE', data)
        } else {
          await this.$store.dispatch('events/UPDATE', {
            ...data,
            id: this.eventToUpdate.id,
          })
        }
        this.$emit('close')
      } catch (error) {
        this._console('error:', error)
      }
      this.loading = false
    },
    resetForm() {
      this.formEl.resetFields()
    },
    clearValidate() {
      this.formEl.clearValidate()
    },
    setDefaults() {
      const data = {}
      Object.keys(this.data).forEach((key) => {
        if (key === 'guaranteed') {
          data[key] = false
        } else {
          data[key] = ''
        }
      })
      if (this.currentType === 'waybill') {
        data.start_date = new Date()
      } else {
        data.datetime = new Date()
      }
      if (this.currentType === 'service') {
        data.guaranteed = true
        data.service_name =
          this.servicesUnical && this.servicesUnical.length
            ? this.servicesUnical[0]
            : ''
      }
      this.data = Object.assign(this.data, data)
    },
    async getRandomTypes() {
      const data = {
        from: 0,
        count: 100,
        conditions: [
          {
            field: 'unit.id',
            value: this.unit.id,
          },
          {
            field: 'type.key',
            value: 'random',
          },
        ],
      }
      await this.$store.dispatch('events/GET_LIST', data)
    },
  },
  async created() {
    if (this.title === 1) {
      this.setDefaults()
    }
    await this.$store.dispatch('dictionary/getDictionaryByType', 'event_type')
    this.dateFormat = this.me.active_company.dateformat.key
    this.timeFormat = this.me.active_company.timeformat.key
    this.dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`
    if (this.title === 2) {
      const event = this.eventToUpdate
      this.currentType = event.type.key
      this.data = Object.assign(this.data, event)
      if (this.data.datetime) {
        this.data.datetime = new Date(event.datetime)
      }
      if (this.data.start_date) {
        this.data.start_date = new Date(event.start_date)
      }
      if (this.data.finish_date) {
        this.data.finish_date = new Date(event.finish_date)
      }
    }
  },
  mounted() {
    if (this.title === 2) {
      const event = this.eventToUpdate
      this.currentType = event.type.key
    }
  },
}
</script>

<style lang="stylus">
.skif-event-form {
  .el-date-editor {
    width: 13rem;
  }
}

.skif-form {
  &__row {
    margin: 20px 0;

    &.--flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__label {
    display: block;
    margin: 0 0 15px 0;
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #5477A9;
  }

  &__icon {
    width: 1em;
    height: 1em;
    vertical-align: baseline;
  }

  &__info-text {
    margin: 30px 0 0 0;
    color: #5477A9;
    font-size: 14px;
    line-height: 1;
  }

  &__star {
    color: #EB5757;
    display: inline-block;
    margin-right: 0.3em;
  }
}
</style>